import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import LogoImage from '../images/logo-7eee1424c68d2b470f6208223e56dcc9.png';
import leftArrowSmall from '../images/left-arrow-small.svg';

const StyledWrapper = styled.header`
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  grid-gap: 12px;
  height: 102px;
  background-color: #fff;
  width: 60%;
  margin: 10px auto;

  @media (max-width: 1280px) {
    width: 80%;
  }

  @media (max-width: 1000px) {
    grid-template-columns: auto 1fr;
    height: auto;
  }

  @media (max-width: 800px) {
    width: 90%;
    height: auto;
  }

  @media (max-width: 480px) {
    width: 95%;
  }
`

const LogoLink = styled(Link)`
  align-self: center;
`;

const Logo = styled.img`
  width: auto;
`;

const H1 = styled.h1`
  display: flex;
  align-items: center;
`;

const OrthopodoLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media(max-width: 1000px) {
    grid-column: span 2;
    justify-content: center;
  }
`;

const BackLink = styled(Link)`
  display: flex;
    align-items: center;
    justify-content: flex-end;

    @media(max-width: 1000px) {
      grid-column: span 2;
      justify-content: center;
    }
  }
`

const Img = styled.img`
  margin-right: 15px;
`;

const Header = () => (
  <StyledWrapper>
    <LogoLink to="/">
      <Logo src={LogoImage} alt="logo" />
    </LogoLink>
    <H1>Orthopodo Standort Finder</H1>
    {
      ( typeof window !== 'undefined' && ( window.location.pathname.includes('/location-details') || window.location.pathname.includes('/booking') ) ) 
        ? <BackLink to="/">
            <button>
              <Img src={leftArrowSmall} alt='left-arrow'></Img>
              zur Standortübersicht
            </button>
          </BackLink>
      : <OrthopodoLink href="http://www.orthopodo.ch">
          <button>
            <Img src={leftArrowSmall} alt='left-arrow'></Img>
            Orthopodo.ch
          </button>
        </OrthopodoLink>
    }
  </StyledWrapper>
)

export default Header;