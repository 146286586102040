import  { createGlobalStyle } from 'styled-components';
import ProximaNovaRegular from '../fonts/ProximaNova-Regular.otf';
import ProximaNovaBold from '../fonts/ProximaNova-Bold.otf';
import GaramondCondRegular from '../fonts/GARAMOND-CONDENSED-LIGHT-REGULAR.ttf';
import GaramondCondBold from '../fonts/GARAMOND-CONDENSED-BOLD.ttf';

const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  @font-face {
    font-family: ProximaNova-Regular;
    src: url(${ProximaNovaRegular});
    font-display: fallback;
  }

  @font-face {
    font-family: ProximaNova-Bold;
    src: url(${ProximaNovaBold});
    font-display: fallback;
  }

  @font-face {
    font-family: GaramondCond-Regular;
    src: url(${GaramondCondRegular});
    font-display: fallback;
  }

  @font-face {
    font-family: GaramondCond-Bold;
    src: url(${GaramondCondBold});
    font-display: fallback;
  }

  h1 {
    margin: 0px;
    font-size: 30px;
    color: #009ba8;
    letter-spacing: 0.05px;
    font-family: GaramondCond-Bold, sans-serif;

    @media(max-width: 700px) {
      font-size: 24px;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  button {
    font-family: ProximaNova-Regular, sans-serif;
    padding: 14px 22px;
    // height: 50px;
    background-color: #009ba8;
    color: #fff;
    font-size: 14px;
    border: none;
    cursor: pointer;
  }
`

export default GlobalStyle;